import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InpatientComponent } from './inpatient/inpatient.component';
import { ServiceChargedComponent } from './service-charged/service-charged.component';
import { RouterModule, Routes } from '@angular/router';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { MaterialModule } from '../shared/material/material.module';

import { SharedModule } from '../shared/shared.module';
import { InpatientSearchComponent } from './inpatient-search/inpatient-search.component';
import { AddInpatientServiceComponent } from './add-inpatient-service/add-inpatient-service.component';
import { BillSummaryComponent } from './bill-summary/bill-summary.component';
import { PreAuthComponent } from '../shared/pre-auth/pre-auth.component';
import { PaymentProcessComponent } from '../shared/payment-process/payment-process.component';
import { CreditBillSubmissionComponent } from '../credit-bill-submission/credit-bill-submission.component';
import { ViewReceiptsComponent } from './view-receipts/view-receipts.component';
import { ServicesChargedComponent } from './services-charged/services-charged.component';
import { ViewBillComponent } from './view-bill/view-bill.component';
import { BillingInsuranceComponent } from './billing-insurance/billing-insurance.component';
import { MasterManagementModule } from '../master-management/master-management.module';
import { EssentialityComponent } from './essentiality/essentiality.component';
import { EmergencyComponent } from './emergency/emergency.component';
import { GenuinenessComponent } from './genuineness/genuineness.component';
import { NoClaimComponent } from './no-claim/no-claim.component';

const viewInpatientRouting: Routes = [
  {
    path: 'Inpatient',
    component: InpatientComponent
  },
  {
    path: 'ServiceCharged/:id',
    component: ServiceChargedComponent
  },
  {
    path: 'DateWiseServiceCharged/:id',
    component: ServicesChargedComponent
  },
  {
    path: 'AddInpatientServices/:id',
    component: AddInpatientServiceComponent
  },
  {
    path: 'BillSummary/:id',
    component: BillSummaryComponent
  },
  {
    path: 'ViewReceipt/:id',
    component: ViewReceiptsComponent
  },
  {
    path: 'ViewBill/:id',
    component: ViewBillComponent
  },
  {
    path: 'BillingInsurance/:id',
    component: BillingInsuranceComponent
  },
  {
    path: 'Essentiality/:id',
    component: EssentialityComponent
  },
  {
    path: 'Emergency',
    component: EmergencyComponent
  }
];

@NgModule({
  declarations: [
    InpatientComponent,
    ServiceChargedComponent,
    AddInpatientServiceComponent,
    BillSummaryComponent,
    PreAuthComponent,
    PaymentProcessComponent,
    CreditBillSubmissionComponent,
    ViewReceiptsComponent,
    ServicesChargedComponent,
    ViewBillComponent,
    BillingInsuranceComponent,
    EssentialityComponent,
    EmergencyComponent,
    GenuinenessComponent,
    NoClaimComponent
  ],
  exports: [
    RouterModule,
    InpatientComponent,
  ],
  imports: [
    SharedModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    MasterManagementModule,
    RouterModule.forChild(viewInpatientRouting)
  ]
})
export class ViewinpatientModule { }
