import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  // @ViewChild('DoctorWisePatientsListPopup') 
  // private DoctorWisePatientsListPopup: ElementRef; 

  fileName1= 'DoctorWiseCollectionsReportSheet.xlsx';
  fileName2= 'DoctorWisePatientDetailsSheet.xlsx';

  public bodyDisplay = 'reports';
  userInfo: any;
  doctorsList: any;
  consultsListForm: FormGroup;
  patientsListForm: FormGroup;
  control: AbstractControl;
  doctorWiseInfo: any;
  paymentModes = [
    { name: 'Cash', id: '1' },
    { name: 'Card', id: '2' },
    { name: 'Wallet', id: '3' },
  ];
  submitted = false;
  totalCashAmnt: any;
  totalCardAmnt: any;
  toatlWalletsAmnt: any;
  navigation: boolean;
  pathname: string;
  doctorWisePatientsInfo: any;
  doctorName: any;

  constructor(
    private tokenService: TokenStorageService,
    private inpatientService: InpatientService,
    private printerService: NgxPrinterService,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    private router: Router, 
    private modalService: NgbModal) 
    { 
      this.userInfo = this.tokenService.getUser();

      this.route.url.subscribe((param) => {
        console.log("Parameters :",param[0].path);
        this.pathname = param[0].path;
      });
    }

  ngOnInit(): void {
    
    this.consultsListForm = new FormGroup({
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required]),
      doctorName: new FormControl('',[Validators.required]),
      paymentType: new FormControl('',[Validators.required])
    });
    this.patientsListForm = new FormGroup({
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required]),
      doctorName: new FormControl('',[Validators.required]),
      paymentType: new FormControl('',[Validators.required])
    });
    this.getDoctorsList();
  }

  get f(): any { return this.consultsListForm.controls; }
  get g(): any { return this.patientsListForm.controls; }

  getDoctorsList() {
    const input = {
      hid: this.userInfo.hospitalID,
      serviceTypeId: 1
    }
    this.inpatientService.getServiceDoctorsList(input).subscribe((response)=>{
      //console.log("Service Doctors list: ", response);
      this.doctorsList = response;
    });
  }

  backTo(){
    if(this.pathname == 'registration'){
      this.router.navigate(['/registration']);
    }else {
      this.router.navigate(['/billing']);
    }
  }
  billingReportsPopup(billingReportsPopupBlock) {
    this.modalService.open(billingReportsPopupBlock);
  }
  doctorReportsPopup(doctorReportsPopupBlock) {
    this.modalService.open(doctorReportsPopupBlock);
  }

  submitData(DoctorWisePatientsListPopup){    
    this.getDoctorWiseInfo(DoctorWisePatientsListPopup);
  }
  submitForm(PatientsListPopup){
    this.getDoctorWisePatientsInfo(PatientsListPopup);
  }

  getDoctorWiseInfo(DoctorWisePatientsListPopup): any {  
    this.submitted = true;
    const request = {
      hid: this.userInfo.hospitalID,
      fromDate: this.consultsListForm.controls.startDate.value,
      toDate: this.consultsListForm.controls.endDate.value,
      paymentMode: this.consultsListForm.controls.paymentType.value,
      serviceId: this.consultsListForm.controls.doctorName.value
    };
    this.inpatientService.getDoctorWiseServiceSearch(request).subscribe((response)=>{
      
        if(response.data != 0){
        this.doctorWiseInfo = response.data;
        this.submitted = false;        
        this.openDoctorWiseListPopup(DoctorWisePatientsListPopup);
        } else {
          this.toasterService.error("No Records Found");
        }
    });
  }

  getDoctorWisePatientsInfo(PatientsListPopup){
    this.submitted = true;
    const request = {
      hid: this.userInfo.hospitalID,
      fromDate: this.patientsListForm.controls.startDate.value,
      toDate: this.patientsListForm.controls.endDate.value,
      paymentMode: '',
      serviceId: this.patientsListForm.controls.doctorName.value
    };
    this.inpatientService.getDoctorWiseServiceSearch(request).subscribe((response)=>{
      
        if(response.data != 0){
        this.doctorWisePatientsInfo = response.data;
        this.doctorName = this.doctorWisePatientsInfo[0].serviceName;
        this.submitted = false;        
        this.openPatientsListPopup(PatientsListPopup);
        } else {
          this.toasterService.error("No Records Found");
        }
    });
  }
  
  public openDoctorWiseListPopup(DoctorWisePatientsListPopup): any {
    this.modalService.open(DoctorWisePatientsListPopup, {size:'xl', backdrop:'static'});
  }
  public openPatientsListPopup(PatientsListPopup): any {
    this.modalService.open(PatientsListPopup, {size:'xl', backdrop:'static'});
  }
  
  printCollectionsList(){
    const htmlele = document.getElementById('collections-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('collections-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }


  printPatientsList(){
    const htmlele = document.getElementById('patients-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('patients-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }

  patientListPopupClose(){
    this.modalService.dismissAll();
  }
  setValuestoEmpty(){
    this.consultsListForm.reset();
    this.patientsListForm.reset();
  }
  
  dataExport(): void 
    {
       /* table id is passed over here */   
       const element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName1);
			
    }

    dataToExport(): void 
    {
       /* table id is passed over here */   
       const element = document.getElementById('excel-data'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName2);
			
    }

}
