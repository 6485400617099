<div>
    <div class="clearfix">
        <button class="btn btn-primary btn-sm my-2 float-right" (click)="printDischargeSummary()">Print</button>
    </div>

    <div id="print-discharge-summary-section">
        <div style="height: 50px;"></div>
        <h4 class="mr-5 pr-3"><strong><u>
                    <p class="textCenter" *ngIf="selectedBed?.headingTitle">{{selectedBed?.headingTitle}}</p>
                </u></strong></h4>
        <h4 class="mr-5 pr-3"><strong><u>
                    <p class="textCenter">{{selectedBed.dischargeType}}</p>
                </u></strong></h4><br />
        <fieldset class="legend-block mt-5 py-2">
            <legend class="legend-title blue-title mb-0">Patient Information</legend>
            <!-- Patient Info -->
            <div class="row patient-info print-information" style="font-weight: bolder;">
                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            UMR No
                        </div>
                        <div class="col">
                            <span>: {{selectedBed.UMRNo}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            IP No
                        </div>
                        <div class="col">
                            <span>: {{selectedBed.IpNr}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Patient Name
                        </div>
                        <div class="col">
                            <span>: {{selectedBed.title}} {{selectedBed.PatientName}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Gender/Age
                        </div>
                        <div class="col">
                            <span>: {{selectedBed.Gender}}/{{selectedBed.Age}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Consult Doctor
                        </div>
                        <div class="col">
                            <span>: {{selectedBed.ConsultDoctor}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2" *ngIf="userInfo.hospitalShortName != 'KPMTA'">
                    <div class="row">
                        <div class="col-5">
                            Referral Doctor
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.refDoctorName}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2" *ngIf="userInfo.hospitalShortName == 'KPMTA'">
                    <div class="row">
                        <div class="col-5">
                            Mobile No
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.mobileNo}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Admission Date
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.admissionDate}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Discharge Date
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.dischargeDate | date: 'dd MMM yyyy'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Address
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.address}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="row">
                        <div class="col-5">
                            Discharge Type
                        </div>
                        <div class="col">
                            <span>: {{selectedBed?.dischargeType}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Patient Info -->
        </fieldset>

        <div class="text-right my-1 clearfix noprint"
             *ngIf="isDischargeSummaryExist">
            <i class="fas fa-edit close handCursor" (click)="editOption = false" *ngIf="editButton"
                style="color: #173267" title="click here to edit form"></i>
        </div>
        <fieldset class="legend-block mb-3 py-2" *ngIf="editOption">
            <div class="mt-4 print-editor-context">
                <div [innerHTML]="summaryInfo | safeHtml"></div>
            </div>
        </fieldset>
        <fieldset class="legend-block mb-3 py-2" *ngIf="!editOption">
            <form (ngSubmit)="updateDischargeSumary(dischargeSummaryForm)" [formGroup]="dischargeSummaryForm"
                class="mb-5">
                <div *ngIf="!existingRecord">
                    <div class="row mb-3">
                        <div class="col-md-3 font-weight-bold">Department Type<span
                                class="color text-danger">*</span></div>
                        <div class="col-md-7">
                            <select id="departmentType" 
                                    class="form-control-sm form-control border border-primary"
                                    formControlName="departmentType" 
                                    placeholder="Select Department Type">
                                <option [ngValue]="''">-- Select Department Type --</option>
                                <option *ngFor="let department of departments" 
                                        [ngValue]="department.DEPT_HEADING_TITLE">
                                    {{ department.DEPT_HEADING_TITLE }}
                                </option>
                            </select>
                            <span
                                *ngIf="(dischargeSummaryForm.controls.departmentType.touched && dischargeSummaryForm.controls.departmentType.invalid) || (dischargeSummaryForm.controls.departmentType.invalid && showErrorMessage)"
                                class="bg-warningtxt">Department Type is required</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-3 font-weight-bold">Discharge Type<span
                                class="color text-danger">*</span></div>
                        <div class="col-md-7">
                            <select id="dischargetype" 
                                    class="form-control-sm form-control border border-primary"
                                    formControlName="dischargeType" 
                                    placeholder="Select Discharge Type">
                                <option [ngValue]="''">-- Select Discharge Type --</option>
                                <option *ngFor="let type of dischargeTypesList" 
                                        [ngValue]="type.DISPLAY_DISCHARGE_SUMMARY">
                                    {{ type.DISPLAY_DISCHARGE_SUMMARY }}
                                </option>
                            </select>
                            <span
                                *ngIf="(dischargeSummaryForm.controls.dischargeType.touched && dischargeSummaryForm.controls.dischargeType.invalid) || (dischargeSummaryForm.controls.dischargeType.invalid && showErrorMessage)"
                                class="bg-warningtxt">Discharge Type is required</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-3 font-weight-bold">
                            Discharge Date
                        </div>
                        <div class="col-7">
                            <input type="date" 
                                    class="form-control-sm form-control border border-primary px-2"
                                    [max]="maxDate"
                                    [min]="minDate"
                                    formControlName="dischargeDate" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="diagnosisForm" (ngSubmit)="onSubmitDiagnosis()">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label class="font-weight-bold">Provision Diagnosis </label>
                                </div>
                                <div class="col-md-5">
                                    <textarea type="text" 
                                            class="form-control-sm form-control border border-primary" 
                                            name="provisionDiagnosis" columns="5"
                                            formControlName="provisionalDiagnosis"                                          
                                            required
                                            ></textarea>
                                            <!-- [readOnly]="isFormReadonlyMode" -->
                                    <!-- <span *ngIf="diagnosisForm.submitted && provisionDiagnosis.invalid" class="bg-warningtxt">
                                    Provision Diagnosis is required
                                    </span> -->
                                </div>
                                <div class="col-md-3">
                                    <button type="submit" class="btn btn-primary px-2">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                
                    <div class="col-md-6">
                        <form [formGroup]="followupForm" (ngSubmit)="onSubmitFallowupDate()">
                                <div class="form-group row">
                                    <label class="col-md-4 font-weight-bold">Follow-up Date</label>
                                    <input class="col-md-5 form-control border border-primary" type="date" 
                                    formControlName="fdate"
                                    >
                                    <div class="col-md-3">
                                        <button class="btn btn-primary px-2" type="submit">Save</button>
                                    </div>
                                    <!-- <span *ngIf="fallowupForm.submitted && fdate.invalid" class="bg-warningtxt">
                                        Provision Diagnosis is required
                                    </span> -->
                                </div>
                        </form>
                    </div>
                </div>                
                <div class="row">
                    <label class="col-md-2"><b>Diagnosis</b></label>
                    <span class="col-md-10"><b>{{diagnosis}}</b></span>
                </div>
                <div class="mt-4 mb-1 font-weight-bold">Discharge Summary<span class="color pl-2 text-danger">*</span>
                </div>
                <div class="NgxEditor__Wrapper">
                  <ckeditor [config]="{ extraPlugins: 'justify',
                                        toolbarGroups: [
                                            { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                                            { name: 'insert' },
                                            { name: 'tools' },
                                            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                            { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                                            { name: 'styles' },
                                            { name: 'colors' }
                                            ] 
                                        }"
                            formControlName="editorContent"></ckeditor>
                </div>
                <div class="clearfix">
                    <p class="float-left mt-3">
                        <a href="javascript:void(0)" 
                        *ngIf="isFinalizedShow"
                           (click)="finalizePopupOpen(finalizePopup)">Finalize the discharge summary</a>
                    </p>
                    <div class="float-right">
                        <button class="btn btn-primary mt-2"
                            [disabled]="dischargeSummaryInfo?.DISCHARGE_SUMMARY_FINALIZED == 'Y'">Save</button>
                    </div>
                </div>
                
            </form>
        </fieldset>
    </div>
</div>

<!-- discharge summary finalize popup -->
<ng-template #finalizePopup let-modal>
    <div class="modal-header">
        <h6 class="modal-title">
            You Selected the option to Finalize the Discharge Summary. On submitting you will not have option to edit. Please Click 'Yes' to Continue and 'No' to Go back
        </h6>
    </div>
    <div class="modal-body">
        <button class="btn btn-success mr-2" (click)="dischargeSummaryfinalizeConfirmation()">Yes</button>
        <button class="btn btn-danger" type="button" aria-label="Close"
            (click)="modal.dismiss('Cross click')">No</button>
    </div>
</ng-template>
<!-- ./END discharge summary finalize popup -->