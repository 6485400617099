<!-- <div class="land">
  <div class="container-fluid ">
    <div class="row justify-content-md-center">
      <div class="m-3">
        <button type="button" routerLink="/OutPatient" class="btn btn-sm btnn btn-light">View Outpatients</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/Inpatient" class="btn btn-sm btnn btn-light">View Inpatients</button>
      </div>
      <div class="m-3">
        <button type="button" class="btn btn-sm btnn btn-light">Search/View OP Bills</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/reports" class="btn btn-sm btnn btn-light">Reports</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/Enquiry" class="btn btn-sm btnn btn-light">Enquiry</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/registration" class="btn btn-sm btnn btn-light" >Registration</button>
      </div>
    </div>
  </div>
</div> -->
<div *ngIf="bodyDisplay === 'reports'">

  <div class="row mt-4 mx-4 font-16">
    <div class="col-2">
      <a href="javascript:void(0)" (click)="billingReportsPopup(billingReportsPopupBlock)">Doctor wise collections</a>
    </div>
    <div class="col-2">
      <a href="javascript:void(0)" (click)="doctorReportsPopup(doctorReportsPopupBlock)">Doctor wise patient details</a>
    </div>
    <div class="col-6">
    </div>
    <div class="col-2 text-right">
      <button (click)="backTo()" class="btn mx-1 text-white" style="background-color: #173267;">Back</button>
    </div>
  </div>
</div>
<!-- billing reports popup -->
<ng-template #billingReportsPopupBlock let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Input Controls</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="consultsListForm" (ngSubmit)="submitData(DoctorWisePatientsListPopup)">
      <!-- (ngSubmit)="submitData()" -->
      <div style="font-size: 16px;">
        <div class="row mt-3 mb-3">
          <label for="doctorname" class="col-md-5 font-weight-bold font">Doctor Name</label>
          <div class="col-md-7">
            <select class="form-control-sm form-control border border-primary" name="doctorname"
              formControlName="doctorName" 
              [ngClass]="{ 'is-invalid': submitted && f.doctorName.errors }" id="doctorname">                    
              <option value="">Select Doctor Name</option>
              <option *ngFor="let doctor of doctorsList; let i = index" [value]="doctor.serviceId">
                {{doctor.serviceName}}
              </option>
            </select>
            <div *ngIf="submitted && f.doctorName.errors" class="invalid-feedback">
              <div *ngIf="f.doctorName.errors.required">Doctor Name is required</div>
            </div>
          </div>                                
        </div>

        <div class="row mt-3 mb-3">
          <label for="payment" class="col-md-5 font-weight-bold font">Mode of Payment</label>
          <div class="col-md-7">
            <select class="form-control-sm form-control border border-primary" name="payment"
              formControlName="paymentType" 
              [ngClass]="{ 'is-invalid': submitted && f.paymentType.errors }" id="payment">                    
              <option value="">Select Payment Type</option>
              <option *ngFor="let mode of paymentModes; let i = index" [value]="mode.id">
                {{mode.name}}
              </option>
            </select>
            <div *ngIf="submitted && f.paymentType.errors" class="invalid-feedback">
              <div *ngIf="f.paymentType.errors.required">Payment Type is required</div>
            </div>
          </div>                                
        </div>

        <div class="row mt-3 mb-3">
            <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
            <div class="col-md-7">
              <input type="date" class="form-control-sm form-control border border-primary" 
              name="start" placeholder="DD-MM-YYYY"
              formControlName="startDate"
              [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }"
                id="start" required>
              
              <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">From Date is required</div>
              </div>
            </div>
        </div>
        <div class="row mt-3 mb-3">
          <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
          <div class="col-md-7">
            <input type="date" class="form-control-sm form-control border border-primary" 
            name="end" placeholder="DD-MM-YYYY"
            formControlName="endDate"
            [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }"
              id="end" required>
            
            <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
              <div *ngIf="f.endDate.errors.required">To Date is required</div>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-3 mb-3">
          <label class="col-md-5 font-weight-bold font">Mode of Payment
            <span class="requiredSymbol">*</span></label>
            <div class="col-md-7">
              <label class="radio-inline" for="cash">
              <input type="radio"
                      class="mx-2" 
                      id="paymentMode" 
                      name="paymentMode"
                      formControlName="paymentMode"                     
                      value="CASH"><span class="font">Cash</span>
              </label>                   
      
              <label class="radio-inline" for="card">
              <input type="radio" 
                      class="mx-2" 
                      id="paymentMode" 
                      name="paymentMode"
                      formControlName="paymentMode"                      
                      value="CARD"><span class="font">Card</span>
              </label>       
      
              <label class="radio-inline" for="wallet">
              <input type="radio" 
                      class="mx-2" 
                      id="paymentMode" 
                      name="paymentMode"
                    formControlName="paymentMode"                     
                      value="WALLET"><span class="font">Wallet</span>
              </label>         
            </div>
        </div> -->                
      </div>

      <div class="text-right mt-5 mb-1 mr-5">
        <button type="submit" class="col-md-2 btn mx-2 p-2 text-white"
          style="background-color: #173267; width: 80px">Submit</button>
        <button (click)="setValuestoEmpty()" type="button"
          class="col-md-2 btn mx-2 resetSub p-2 text-white"
          style="background-color: #173267; width: 80px">Reset</button>        
      </div>      
    </form>
  </div>
</ng-template>

<ng-template #doctorReportsPopupBlock let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Input Controls</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="patientsListForm" (ngSubmit)="submitForm(PatientsListPopup)">
      <!-- (ngSubmit)="submitData()" -->
      <div style="font-size: 16px;">
        <div class="row mt-3 mb-3">
          <label for="doctorname" class="col-md-5 font-weight-bold font">Doctor Name</label>
          <div class="col-md-7">
            <select class="form-control-sm form-control border border-primary" name="doctorname"
              formControlName="doctorName" 
              [ngClass]="{ 'is-invalid': submitted && g.doctorName.errors }" id="doctorname">                    
              <option value="">Select Doctor Name</option>
              <option *ngFor="let doctor of doctorsList; let i = index" [value]="doctor.serviceId">
                {{doctor.serviceName}}
              </option>
            </select>
            <!-- <div *ngIf="submitted && f.doctorName.errors" class="invalid-feedback">
              <div *ngIf="f.doctorName.errors.required">Doctor Name is required</div>
            </div> -->
          </div>                                
        </div>

        <!-- <div class="row mt-3 mb-3">
          <label for="payment" class="col-md-5 font-weight-bold font">Mode of Payment</label>
          <div class="col-md-7">
            <select class="form-control-sm form-control border border-primary" name="payment"
              formControlName="paymentType" 
              [ngClass]="{ 'is-invalid': submitted && f.paymentType.errors }" id="payment">                    
              <option value="">Select Payment Type</option>
              <option *ngFor="let mode of paymentModes; let i = index" [value]="mode.id">
                {{mode.name}}
              </option>
            </select>
            <div *ngIf="submitted && f.paymentType.errors" class="invalid-feedback">
              <div *ngIf="f.paymentType.errors.required">Payment Type is required</div>
            </div>
          </div>                                
        </div> -->

        <div class="row mt-3 mb-3">
            <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
            <div class="col-md-7">
              <input type="date" class="form-control-sm form-control border border-primary" 
              name="start" placeholder="DD-MM-YYYY"
              formControlName="startDate"
              [ngClass]="{ 'is-invalid': submitted && g.startDate.errors }"
                id="start" >
              
              <!-- <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">From Date is required</div>
              </div> -->
            </div>
        </div>
        <div class="row mt-3 mb-3">
          <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
          <div class="col-md-7">
            <input type="date" class="form-control-sm form-control border border-primary" 
            name="end" placeholder="DD-MM-YYYY"
            formControlName="endDate"
            [ngClass]="{ 'is-invalid': submitted && g.endDate.errors }"
              id="end" >
            
            <!-- <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
              <div *ngIf="f.endDate.errors.required">To Date is required</div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="text-right mt-5 mb-1 mr-5">
        <button type="submit" class="col-md-2 btn mx-2 p-2 text-white"
          style="background-color: #173267; width: 80px">Submit</button>
        <button (click)="setValuestoEmpty()" type="button"
          class="col-md-2 btn mx-2 p-2 resetSub text-white"
          style="background-color: #173267; width: 80px">Reset</button>        
      </div>      
    </form>
  </div>
</ng-template>

<ng-template #DoctorWisePatientsListPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Doctor Wise Collections Report</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-bordered table-striped">
      <!--Table head-->
      <thead>
        <tr>
          <th class=" fweight600 wid120">S.No </th>
          <th class=" fweight600 wid120">Patient Name</th>
          <th class=" fweight600  wid120">Visit ID</th>
          <th class="fweight600 wid120">Payment Date</th>
          <th class="fweight600 wid120">Service Name</th>          
          <th class="fweight600 wid120">Cash <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th class="fweight600 wid120">Card <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th class="fweight600 wid120">Wallets <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Cash)</span></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Card)</span></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Wallets)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let patient of doctorWiseInfo; let i = index" class="handCursor">
          <td>{{i+1}}</td>
          <td>{{patient.patientName}}</td>
          <td>{{patient.visitId}}</td>
          <td>{{patient.paymentDate}}</td>
          <td>{{patient.serviceName}}</td>
          <td>{{patient.cashamount}}</td>
          <td>{{patient.cardamount}}</td>
          <td>{{patient.walletsamount}}</td>
          <td></td>
          <td>{{patient.cashttlamount}}</td>
          <td>{{patient.cardttlamount}}</td>
          <td>{{patient.walletttlamount}}</td>
        </tr>
      </tbody>
    </table>    
    <div class="text-center">
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="patientListPopupClose()" type="submit" value="Close">Close</button>
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="printCollectionsList()" type="button" value="Print">Print</button>
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="dataExport()" type="button">Export to Excel</button>
    </div>
  </div>  
</ng-template>

<ng-template #PatientsListPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Doctor Wise Patient Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h5><b>Doctor Name: {{doctorName}}</b></h5>
    </div><br>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th class="fweight600 wid120">S.No </th>
          <th class="fweight600 wid120">Visit ID</th>
          <th class="fweight600 wid120">UMR No</th>
          <th class="fweight600 wid120">Patient Name</th>
          <th class="fweight600 wid120">Gender</th>
          <th class="fweight600 wid120">Age</th>
          <th class="fweight600 wid120">Mobile No</th>          
          <th class="fweight600 wid120">Address<br></th>
          <th class="fweight600 wid120">Guardian Name<br>(Relation)</th>
          <th class="fweight600 wid120">Guardian Mobile No<br></th>          
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let patient of doctorWisePatientsInfo; let i = index" class="handCursor">
          <td>{{i+1}}</td>
          <td>{{patient.visitId}}</td>
          <td>{{patient.umrno}}</td>
          <td>{{patient.patientName}}</td>
          <td>{{patient.gender}}</td> 
          <td>{{patient.age}}</td>
          <td>{{patient.mobileNo}}</td>
          <td>{{patient.address}}</td>
          <td>{{patient.nextOfKinName}}<br><span *ngIf="patient.nextOfKinRelation">({{patient.nextOfKinRelation}})</span></td>
          <td>{{patient.nextOfkinMobileNo}}</td>          
        </tr>
      </tbody>
    </table>    
    <div class="text-center">
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="patientListPopupClose()" type="submit" value="Close">Close</button>
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="printPatientsList()" type="button" value="Print">Print</button>
      <button class="btn mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="dataToExport()" type="button">Export to Excel</button>
    </div>
  </div>  
</ng-template>

<div id="collections-print-section" style="display: none;" class="print-form mt-5">
  <div class="row m-5">
    <div class="row col-md-12">
      <h4 class="text-center font-weight-bold"> Doctor Wise Collections Report</h4>
      <hr class="m-0 hr" />  
      <table class="table table-bordered table-striped" id="excel-table">
        <!--Table head-->
        <thead>
          <tr>
            <th class=" fweight600 wid120">S.No </th>
          <th class=" fweight600 wid120">Patient Name</th>
          <th class=" fweight600  wid120">Visit ID</th>
          <th class="fweight600 wid120">Payment Date</th>
          <th class="fweight600 wid120">Service Name</th>          
          <th class="fweight600 wid120">Cash <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th class="fweight600 wid120">Card <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th class="fweight600 wid120">Wallets <br><span style="font-size: 13px;">(Consultations fee charges)</span></th>
          <th></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Cash)</span></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Card)</span></th>
          <th class="fweight600 wid120">Receipt Value <br><span style="font-size: 13px;"> (Wallets)</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let patient of doctorWiseInfo; let i = index" class="handCursor">
            <td>{{i+1}}</td>
            <td>{{patient.patientName}}</td>
            <td>{{patient.visitId}}</td>
            <td>{{patient.paymentDate}}</td>
            <td>{{patient.serviceName}}</td>
            <td>{{patient.cashamount}}</td>
            <td>{{patient.cardamount}}</td>
            <td>{{patient.walletsamount}}</td>
            <td></td>
            <td>{{patient.cashttlamount}}</td>
            <td>{{patient.cardttlamount}}</td>
            <td>{{patient.walletttlamount}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div id="patients-print-section" style="display: none;" class="print-form mt-5">
  <div class="row m-5">
    <div class="row col-md-12">
      <div class="row col-md-12 text-center">
        <h4 class=" font-weight-bold"> Doctor Wise Patient Details</h4>
      </div>      
      <br>
      <div class="row col-md-12 text-center">
        <h5><b>Doctor Name: {{doctorName}}</b></h5>
      </div><br>  
      <table class="table table-bordered table-striped" id="excel-data">
        <thead>
          <tr>
            <th class="fweight600 wid120">S.No </th>
            <th class="fweight600 wid120">Visit ID</th>
            <th class="fweight600 wid120">UMR No</th>
            <th class="fweight600 wid120">Patient Name</th>
            <th class="fweight600 wid120">Gender</th>
            <th class="fweight600 wid120">Age</th>
            <th class="fweight600 wid120">Mobile No</th>          
            <th class="fweight600 wid120">Address<br></th>
            <th class="fweight600 wid120">Guardian Name<br>(Relation)</th>
            <th class="fweight600 wid120">Guardian Mobile No<br></th>          
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let patient of doctorWisePatientsInfo; let i = index" class="handCursor">
            <td>{{i+1}}</td>
            <td>{{patient.visitId}}</td>
            <td>{{patient.umrno}}</td>
            <td>{{patient.patientName}}</td>
            <td>{{patient.gender}}</td> 
            <td>{{patient.age}}</td>
            <td>{{patient.mobileNo}}</td>
            <td>{{patient.address}}</td>
            <td>{{patient.nextOfKinName}}<br><span *ngIf="patient.nextOfKinRelation">({{patient.nextOfKinRelation}})</span></td>
            <td>{{patient.nextOfkinMobileNo}}</td>          
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>